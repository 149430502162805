
   .main-first-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    /* background-color: green; */
}

.text-container {
    /* margin-bottom: 20px; */
    /* background-color: red; */
    text-align: center; 
    width: 100%;
}

.text-container h1 {
    font-size: 28px;
    margin-bottom: 5px;
}

.text-container h3 {
    font-size: 18px;
    margin-top: 0;
}

.text-container img {
    width: 100%; 
    max-width: 250px;
}

.mainImage {
    width: 100%;
    max-width: 600px !important;
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {
    .content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center; 
    }

    .text-container {
        text-align: left;
        width: 50%; 
    }

    .header-img {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .main-first-section {
        padding-top: 25px;
    }

    .novuslab_image{
      padding: 3px;
      padding-right: 25px;
    }
    .text-container {
        width: 100%;
    }
    
    .mainImage {
        max-width: 100%; 
    }
    .novusLabPart{
        margin-bottom: 30px !important;
    }

    .novusLabPart h1 {
        font-size: 24px;
    }

    .novusLabPart h3 {
        font-size: 16px;
    }

}





/*  old  */

/* 
.main-first-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.text-container {
    margin-bottom: 20px;
    text-align: center; 
    width: 100%;
}

.text-container h1 {
    font-size: 28px;
    margin-bottom: 5px;
}

.text-container h3 {
    font-size: 18px;
    margin-top: 0;
}

.text-container img {
    width: 100%; 
    max-width: 250px;
}

.mainImage {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {
    .content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center; 
    }

    .text-container {
        text-align: left;
        width: 50%; 
    }

    .mainImage {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .main-first-section {
        padding-top: 25px;
    }

    .novuslab_image {
        padding: 3px;
        padding-right: 25px;
    }

    .text-container {
        width: 100%;
        padding: 0 15px; 
    }

    .mainImage {
        max-width: 100%;
        margin-bottom: 20px; 
    }

    h1 {
        font-size: 24px;
    }

    h3 {
        font-size: 16px;
    }
} */


.body-menu-content {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
}

.body-menu-content div:first-child {
  /* margin-bottom: 100px; */
  /* background-color: red; */
}

.body-menu-content h1 {
  color: #000;
  transition: 0.3s ease;
  font-weight: 300;
  margin-bottom: 20px;
}

.body-menu-content h1:hover {
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.closeIcon {
  cursor: pointer;
  color: #000;
  padding: 5px;
  border: 1.5px solid #d6d6d6;
  border-radius: 50%;
  font-size: 35px !important;
}

.option-tab {
  display: flex;
  flex-direction: column;
}

.option-tab h1 {
  border-radius: 20px;
  font-size: 17px;
  font-weight: 300;
  padding: 5px 20px;
  border: 1.5px solid #d6d6d6;
  margin-bottom: 10px; 
}

.Partnerships-text {
  position: relative;
}

.Partnerships-text::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: -150px;
  width: 470px;
  height: 1px;
  background-color: #000;
}

.tabs-teams {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.tabs-teams p {
  border-radius: 20px;
  font-size: 17px;
  font-weight: 300;
  transition: 0.3s ease;
  padding: 5px 20px;
  border: 1.5px solid #d6d6d6;
}

.tabs-teams p:hover {
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .Partnerships-text::after{
    left: -110px;
    width: 350px;
  }
  .tabs-teams p{
    padding: 10px 20px;
    font-size: 15px;
  }
  .option-tab h1 {
    padding: 10px 20px;
    font-size: 15px;
  }
}

.FoundingTeam{
    padding: 0 30px;
}

.novus h3{
    font-weight: 600;
    font-size: 18px;
    /* color: red; */
}

@media only screen and (max-width: 768px) {
.FoundingTeam{
    padding: 0px;
}
  }

   .main-first-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}


.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px; 
}

.text-container {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
}

.text-container h1 {
    font-size: 28px;
    font-weight: 500;
}

.text-container h3 {
    font-size: 18px;
    font-weight: 400;
}

.mainImage {
    width: 100%;
    /* height: auto;  */
}

.logoImage {
    width: 100%;
    max-width: 250px;
    height: auto; 
    margin-bottom: 20px !important;
}

.header-img img {
    border-radius: 10px;
    width: 100%;
    height: auto; 
}

.shisha_text h1{
    /* color: red; */
    /* margin-bottom: 200px !important; */
}
@media only screen and (min-width: 768px) {
    .content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .text-container {
        text-align: left;
        margin-bottom: 0;
        width: 50%; 
    }

    .header-img {
        width: 50%; 
    }
}

@media only screen and (max-width: 768px) {
    .main-first-section {
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        flex-direction: column;
        text-align: center;
    }

    .text-container {
        text-align: center;
    }

    .header-img {
        width: 100%;
    }
}

.about {
  padding: 100px 0;
  background-color: #EEEEEE;
}

.about h1 {
  /* font-size: 70px; */
  /* font-weight: 300; */
  font-size: 40px;
  font-weight: 300;
  margin: 50px 0;
  text-align: center;
}

.about p {
  font-weight: 200;
  line-height: 1.5;
  font-size: 18px;
}

.main-container {
  /* background-color: red; */
  margin-top: 30px;
  margin-bottom: 70px;
}

.container h1{
  margin-bottom: 0px !important;
}
.container {
  margin: 0 auto;
  max-width: 992px;
}
.container h2{
  /* color: red !important; */
  /* font-weight: 400 !important; */
}

.container p {
  position: relative;
}

.container p::before {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 425px;
  width: 150px;
  height: 1px;
  background-color: #000;
}

.about h2 {
  color: #000;
  font-weight: 400;
  /* color: red ; */
  font-size: 25px;
  margin-bottom: 20px;
}

.about span {
  font-size: 18px;
  margin-top: 0px;
  font-weight: 200;
}

.box-content {
  margin: 40px 0;
}

.lastOne {
  position: relative;
}

.lastOne::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 350px;
  /* left: 41%; */
  width: 150px;
  height: 1px;
  background-color: #000;
}

.Get_in_Touch {
  text-align: center;
  cursor: pointer;
  max-width: 1000px;
  border-radius: 5px;
  border: 1px solid #000;
  margin: 0 auto;
  color: inherit;
  text-decoration: none;
  display: block;
}


.Get_in_Touch h2{
  font-weight: 400;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 25px;

    max-width: 768px;
    /* background-color: red; */
    /* background-color: red; */
  }

  .container p::before {
    left:125px !important;
  }

  .about {
    padding: 50px 0;
  }

  .about .lastOne::after {
    left: 15px;
    /* left: 41%; */

  }

  .container p::before {
    left: 100px;
  }
  .Get_in_Touch{
    margin: 20px;
  }
}
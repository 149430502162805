/* .main-first-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content img:last-child{
  margin-top: 200px;
}

.text-container {
  margin-bottom: 30px !important;
}

.text-container h1 {
  font-weight: 400;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.text-container h3 {
  font-size: 18px;
  margin-top: 8px;
  font-weight: 200;
}

.text-container img {
  width: 250px;
  margin-bottom: 10px;
}

.mainImage {
  height: 100% !important;
  width: 850px !important;
}

.header-img img {
  border-radius: 10px;
  width: 100%;
}

.LinearProgress {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .main-first-section {
    padding-top: 25px;
  }

  .mainImage {
    height: 100% !important;
    width: 100% !important;
    margin: 50px 0px;
  }

  .text-container img {
    width: 100%;
    height: 100%;
  }

  .content img:last-child{
    margin: 0;
  }

  .text-container {
    margin: 0;
  }
}



  */


  .main-first-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .text-container {
    margin-bottom: 30px;
    max-width: 600px;
  }
  
  .text-container h1 {
    font-weight: 400;
    margin: 0;
    font-size: 2rem;
  }
  
  .text-container h3 {
    font-size: 1.125rem;
    margin-top: 8px;
    font-weight: 200;
  }
  
  .text-container img {
    width: 80%;
    max-width: 250px;
    margin-bottom: 10px;
  }
  
  .header-img {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .mainImage {
    max-width: 100%;
    /* height: auto; */
    border-radius: 10px;
  }
  
  .LinearProgress {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  @media only screen and (min-width: 768px) {
    .content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .text-container {
      text-align: left;
      margin-bottom: 0;
    }
  
    .text-container h1 {
      font-size: 3rem;
    }
  
    .text-container h3 {
      font-size: 1.25rem;
    }
  
    .header-img {
      justify-content: flex-end;
      margin-left: 20px;
    }
  
    .mainImage {
      width: 100%;
      max-width: 850px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .main-first-section {
      padding-top: 25px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .text-container {
      margin: 0;
      padding: 0 10px;
    }
  
    .mainImage {
      /* margin: 30px 0; */
      width: 100%;
      max-width: 100%;
    }
  }
  

.background {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 40px;
  color: #000;
}

.centered-text h1 {
  font-weight: 200;
  color: #FFF;
  /* color: #000; */
  /* color: red; */
  /* font-weight: bold; */
  z-index: 100000;
}

.bottom-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white; 
}
.arrow {
  /* color: #000; */
  font-size: 220px;
  color: #FFF;
  /* color: #FFFF; */
  cursor: pointer;
}
.section:nth-child(2){
  /* background-color: red; */
}


@media only screen and (max-width: 768px) {
  
.centered-text h1{
  font-size: 40px;
}
}


/* .centered-text h1 {
  font-weight: 200;
  color: #FFF;
  /* color: #000; */
  /* color: red; */
  /* font-weight: bold; */
  /* z-index: 100000; */
/* } */
.partnershipsPage {
  padding: 100px 0;
  /* background-color: red; */
}

.container-partnerships {
  max-width: 1200px;
  margin: 0 auto;
  /* background-color: red; */
  /* padding-bottom: 20px; */
  padding: 20px;
}

.partnershipsPage h1 {
  font-size: 40px;
  font-weight: 300;
  /* margin: 50px 0 !; */
  text-align: center;
  /* background-color: red; */
}

.grid-image img{
  width: 100%;
  height: auto;
  /* width: 100%; */
  /* height: 100%; */
}
.grid-image {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* padding: 35px; */
  gap: 50px;
  align-items: center;
}
/* @media screen and (max-width: 600px) {
  .grid-image {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .grid-image img{
    margin: 25px 0;
  }
} */

@media only screen and (min-width: 600px) and  (max-width: 768px) {
  .grid-image {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media only screen and (min-width: 769px) and  (max-width: 935px) {
    .grid-image {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 599px) {
    .grid-image {
      grid-template-columns: repeat(1, 1fr);
    }
    .partnershipsPage h1{
      /* padding: 30px 100px !important; */
      /* color: red; */
      padding: 5px;
      /* font-size: 26px; */
    }
    .container-partnerships {
      padding: 50px;
    }

  }


  /* @media screen and (max-width: 600px) {
  .grid-image {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .grid-image img{
    margin: 25px 0;
  }
} */
  
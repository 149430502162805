.main-people {
  background-color: #eeeeee;
  padding-top: 75px;
}
.card-container{
  max-width: 1300px;
  margin: 0 auto;
}

.main-people h1 {
  font-size: 40px;
  font-weight: 300;
  margin: 50px 0;
  text-align: center;
}.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 600px) {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
}

.card-image {
 
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: #fff;
  transition: opacity 0.4s ease;
  opacity: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.card-title {
  position: absolute;
  padding: 0px 25px;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 10;
}

.card-title h2 {
  margin-bottom: 0;
  color: #fff;
}

.card-title p {
  margin-top: 0;
  color: #FFF;
  font-weight: 600;
}


.card:hover .card-image {
  transform: scale(1.1);
}

.card:hover .card-overlay {
  opacity: 0;
}

.card:hover .card-title {
  opacity: 1;
}

/* 
.main-first-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .text-container {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .text-container h2 {
    font-size: 22px;
    font-weight: 300;
  }
  .mainImage {
    width: 100%;
  }
  
  .header-img img{
    border-radius: 10px;
    width: 100%;
  }
  
  .img-header img{
    width: 100%;
  }
  .img-header:first-child img{
    margin-top: -60px !important;
  }

  .byID{
    background-color: #fff;
  }

  .louco-header-img img{
    width: 992px;
  }
  @media only screen and (min-width: 768px) {
    .content {
        flex-direction: row;
        justify-content: space-between;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .main-first-section  {
        padding-top: 25px;
    }
    .uniapp-title{
      font-size: 40px !important;
    }
    .img-header:first-child img{
      margin-top: 30px !important;
    }
    .louco-header-img img{
      width: 100%;
    }
  }
   */

   .byID{
    background-color: #fff;
  }

  .img-header img{
    width: 100%;
  }


   .main-first-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; 
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1300px; 
  margin: auto; 
}

@media only screen and (min-width: 768px) {
  .content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; 
  }
}


.text-container {
  margin-bottom: 20px;
  text-align: left;
  max-width: 500px; 
}

.text-container h1 {
  font-weight: 500;
  font-size: 28px; 
  margin-bottom: 10px; 
}

.text-container h3 {
  font-weight: 300;
  font-size: 18px; 
}

.louco-header-img img {
  width: 100%;
  max-width: 992px; 
  height: auto;
  border-radius: 10px;
}


@media only screen and (max-width: 768px) {
  .main-first-section {
      padding-top: 25px;
  }

  .uniapp-title {
      font-size: 40px !important;
  }

  .img-header:first-child img {
      margin-top: 30px !important;
  }

  .louco-header-img img {
      width: 100%;
  }

  .text-container h1 {
      font-size: 24px;
  }

  .text-container h3 {
      font-size: 16px;
  }
}
